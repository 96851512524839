@use "sass:color"; @import "@frontend/vars.scss";
@import "@frontend/shared/styles/mixins/utilities/animation.mixin.scss";

.ci-content-tile {
  display: flex;
  flex-direction: column;
  &__header {
    position: relative;
  }

  &__image {
    background-color: $gray-100;
    background-position: center;
    background-size: cover;
    padding-bottom: 50%;
    z-index: 1;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    picture {
      height: 100%;
      :deep(img) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &__icon {
    width: 40px;
    height: 36px;
  }
  &__headline {
    .headline {
      margin-bottom: 0;
      flex-grow: 1;
      /* stylelint-disable */
      &.truncated {
        @include media-breakpoint-down(lg) {
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: pre-wrap;
        }
      }
      /* stylelint-enable */
    }
  }
  &__link {
    color: $cap-black;
    text-decoration: none;
    cursor: pointer;
  }

  &__reading_time,
  &__meta {
    .mdi {
      height: 1.5rem;
    }
    & > *:not(.mdi) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.featured {
    flex-direction: row;

    @include media-breakpoint-up(md) {
      height: 16rem;

      .ci-content-tile__header {
        height: 16rem;
        width: 32rem;
        flex-shrink: 0;
      }
    }
  }
  &.short {
    .ci-content-tile__headline {
      margin-top: 0 !important;
      margin-bottom: 0 !important;

      h6 {
        font-size: 1.25rem;
      }
    }

    &.messaging {
      @include media-breakpoint-up(xxl) {
        flex-direction: row;
        min-height: 8rem;
        height: auto;

        .ci-content-tile__header {
          min-height: 8rem;
          height: auto;
          width: 19rem;
          flex-shrink: 0;
        }
      }
    }
  }
}

.fade-enter-active,
.fade-enter-to {
  @include animation("fadeIn 0.5s cubic-bezier(0.75, 0, 0.125, 1) forwards -0.25s");

  @include keyframes("fadeIn") {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}

/* stylelint-disable */
h3.headline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
/* stylelint-enable */
